export const light = {
    body:"#ffe69e",
    text:"#202020",
    bodyRgba : "255, 230, 158",
    textRgba:"32,32,32",

    carouselColor: '#EEEDDE',

    fontxs:'0.75em',
    fontsm:'0.875em',
    fontmd:'1em',
    fontlg:'1.25em',
    fontxl:'2em',
    fontxxl:'3em',
    fontxxxl:'4em',

    fontButton:'0.875em',


    navHeight: '5rem',
}

export const dark = {
    body:"#fce08f",
    text:"#202020",
    bodyRgba : "32, 32, 32",
    textRgba:"255, 255, 255",

    carouselColor: '#EEEDDE',

    fontxs:'0.75em',
    fontsm:'0.875em',
    fontmd:'1em',
    fontlg:'1.25em',
    fontxl:'2em',
    fontxxl:'3em',
    fontxxxl:'4em',

    fontButton:'0.875em',


    navHeight: '5rem',
}